// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Link,
  Container,
  Typography,
  Stack,
  Divider,
  Paper
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { useLanguage } from '../context/LanguageContext';
import FirebaseLogin from '../ui-component/FirebaseLogin';
import LoginCard from '../ui-component/LoginCards';
import LoginImage1 from '../assets/login/login-1.jpg'
import LoginImage2 from '../assets/login/login-2.jpg'
import LoginImage3 from '../assets/login/login-3.jpg'
import LoginImage4 from '../assets/login/login-4.png'
import ExtensionAlert from '../components/ExtensionAlert';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { t } = useLanguage();
  const mdUp = useResponsive('up', 'md');
  const loginTitle = t('login.title'); // Login
  const rightMainSubHead = t('login.right.heading'); // Get started absolutely free.
  const loginMainTitle = t('login.main.title'); // Voice Typing on Every Webpage
  const loginConsentLabel = t('login.consent.label'); // By Login, I agree to Voice Typing
  const labelTerms = t('labelTerms'); // Terms of Service
  const labelPrivacy = t('labelPrivacy'); // Privacy Policy
  const labelAnd = t('and'); // and
  const loginCTitle1 = t('login.ct1'); // Use your voice to type in Gmail and 1000+ other sites.
  const loginCDesc1 = t('login.cd1'); // Whether it is writing emails in Gmail, answering assignments, replying to chats or learning pronunciations, Voice Typing lets you do them all.
  const loginCTitle2 = t('login.ct2'); // Advanced features to make voice typing a breeze.
  const loginCDesc2 = t('login.cd2'); // Includes automatic text formatting, built-in and custom voice commands, language switching shortcuts, text case correction, etc.
  const loginCTitle3 = t('login.ct3'); // Designed to respect your privacy.
  const loginCDesc3 = t('login.cd3'); // Voice In needs only minimal permissions to run. All the audio is transcribed in your browser and no audio or transcript is ever sent to our servers.

  const loginCTitle4 = t('login.ct4'); // ChatGPT-Powered Brainstorming Assistance.
  const loginCDesc4 = t('login.cd4'); //  Enhance your ideas effortlessly with our "ChatGPT-Powered Brainstorming Assistance" integration. Simply activate the "computer" command and express your thoughts to receive instant text generation and creative input. Let ChatGPT be your brainstorming companion, helping you bring your ideas to life with ease and inspiration. Say "computer" and let the creativity flow!

  const items = [
    {
      Name: loginCTitle1,
      Image: LoginImage1,
      Desc: loginCDesc1
    },
    {
      Name: loginCTitle4,
      Image: LoginImage4,
      Desc: loginCDesc4
    },
    {
      Name: loginCTitle2,
      Image: LoginImage2,
      Desc: loginCDesc2
    },
    {
      Name: loginCTitle3,
      Image: LoginImage3,
      Desc: loginCDesc3
    },
  ];
  return (
    <Page title={loginTitle}>
      <ExtensionAlert />
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Carousel style={{ height: '100%' }}>
              {items.map((item, index) => (
                <Paper style={{ height: '100%' }} item={item} key={index} contentPosition={item.contentPosition}>
                  <LoginCard item={item} />
                </Paper>
              ))}
            </Carousel>
          </SectionStyle>
        )}
        <Container>
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              {rightMainSubHead}
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 5 }}>{loginMainTitle}.</Typography>
            <Stack direction="row" spacing={2}>
              <FirebaseLogin />
            </Stack>
            <Divider sx={{ my: 3 }} />
            <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
              {loginConsentLabel}&nbsp;
              <Link underline="always" color="text.primary" href="/terms">
                {labelTerms}
              </Link>
              {''}&nbsp;{labelAnd}&nbsp;{''}
              <Link underline="always" color="text.primary" href="/privacy">
                {labelPrivacy}
              </Link>
              .
            </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
