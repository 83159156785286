import firebase from 'firebase/compat/app';

import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

import {getBrowserLanguage, getBrowserLangId, serverEndpoint} from "./helper";

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseUrl: process.env.REACT_APP_FIREBASE_DATABASE_URL
});

const storage = firebase.storage();

/** log out authenticated user */
const logout = () => {
    firebase.auth().signOut();
};
/** create entry in firestore */
const createOrUpdateEntry = async (collection, userId, isPaid = false) => {
    const document = await firebase.firestore().collection(collection).doc(userId).get();
    if (document && document.exists) {
        await document.ref.update({
            updated: new Date().toISOString(),
            isPaid,
            locale: getBrowserLangId()
        });
        return true;
    }
    await document.ref.set(
        {
            id: userId,
            isPaid,
            created: new Date().toISOString(),
            updated: new Date().toISOString(),
            locale: getBrowserLangId()
        },
        { merge: true }
    );
    return false;
};
let alerted = false;
const checkEntry = async (collection, userId) => {
    let languageLabel;
    let languageLocale;
    let commands = [];
    let preferredLang = [];
    let created;
    let onboarded;
    let translate;
    let targetLanguage;
    const document = await firebase.firestore().collection(collection).doc(userId).get();
    const documentExist = document && document.exists;
    const data = documentExist ? document.data() : {}
    if (!documentExist) {
        const language = getBrowserLanguage();
        await document.ref.set(
            {
                id: userId,
                isPaid: false,
                created: new Date().toISOString(),
                updated: new Date().toISOString(),
                locale: language.locale,
                languageLabel: language.name,
                languageLocale: language.locale,
                usedCount: 0,
                commands: [],
                preferredLang: [],
                onboarded: false,
                translate: false,
                targetLanguage: null
            },
            { merge: true }
        );
        created = new Date();
        languageLabel = language.name;
        languageLocale = language.locale;
        onboarded = false;
        translate = false;
        targetLanguage = null
        if (!alerted) {
            alerted = true;
            fetch(`${serverEndpoint}/new-user-voice-typing?email=${userId}`, {
                method: 'POST'
            }).catch((e) => {});
        }
    } else {
        created = new Date(data.created);
        const cmd = data.commands;
        commands = Array.isArray(cmd) ? cmd: [];
        preferredLang = Array.isArray(data.preferredLang) ? data.preferredLang : []
        languageLabel = data.languageLabel;
        languageLocale = data.locale;
        onboarded = data.hasOwnProperty('onboarded') ? data.onboarded : true;
        translate = data.hasOwnProperty('translate') ? data.translate : false;
        targetLanguage = data.hasOwnProperty('targetLanguage') ? data.targetLanguage : null;
    }

    return {
        isPaid: data.isPaid,
        usedCount: data.usedCount || 0,
        locale: languageLocale,
        languageLabel,
        languageLocale,
        commands,
        created,
        preferredLang,
        onboarded,
        translate,
        targetLanguage

    };
};
const updateUser = async (userId, params) => {
    const document = await firebase.firestore().collection('users').doc(userId).get();
    if (document && document.exists) {
        await document.ref.update({
            updated: new Date().toISOString(),
            ...params
        });
        return true;
    }
    return false;
};
export { storage, app, logout, createOrUpdateEntry, checkEntry, updateUser, firebase };

export default firebase;
