import React, { useState } from "react";
// material-ui
import LinearProgress from '@mui/material/LinearProgress';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Button, Grid } from '@mui/material';
// project imports
import firebase, { checkEntry } from '../services/firebaseService';

import Google from '../assets/images/icons/social-google.svg';
import Facebook from '../assets/images/icons/facebook.svg';
import {useLanguage} from "../context/LanguageContext";
import { getVoiceTypingStatus } from '../services/helper';

// style constant
const useStyles = styled((theme) => ({
    redButton: {
        fontSize: '1rem',
        fontWeight: 500,
        backgroundColor: theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.grey[100],
        color: theme.palette.grey[700],
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.875rem'
        }
    },
    signDivider: {
        flexGrow: 1
    },
    signText: {
        cursor: 'unset',
        margin: theme.spacing(2),
        padding: '5px 56px',
        borderColor: `${theme.palette.grey[100]} !important`,
        color: `${theme.palette.grey[900]}!important`,
        fontWeight: 500
    },
    loginIcon: {
        marginRight: '16px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '8px'
        }
    },
    loginInput: {
        ...theme.typography.customInput
    }
}));

const theme = createTheme({
    spacing: '2rem',
    transitions: {
        duration: 2
    }
});

//= ===========================|| FIREBASE - LOGIN ||============================//

const FirebaseLogin = (props, { ...others }) => {
    const [loading, setLoading] = useState(false);
    const [loadingFacebook, setLoadingFacebook] = useState(false);
    const { t } = useLanguage();
    const classes = useStyles();

    const handleOnboarding = async (user) => {
        const { message, hasCapability } = await getVoiceTypingStatus();
        if (!hasCapability) {
            alert(message);
        } else if (!user.onboarded) {
            window.location.href = '/onboard/permission';
        } else {
            window.location.href = "/"
        }
    };

    const loginWithGoogleLabel = t('loginWithGoogleLabel'); // Sign in with Google
    const loginWithFacebookLabel = t('loginWithFacebookLabel'); // Sign in with Facebook

    const googleHandler = async () => {
        setLoading(true)
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase
            .auth()
            .signInWithPopup(provider)
            .then(async (result) => {
                console.log("googleHandler", {result});
                const user = await checkEntry('users', result.user.email);
                handleOnboarding(user);
                // navigate('/');
            })
            .catch((error) => {
                setLoading(false);
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const { email } = error;
                // The firebase.auth.AuthCredential type that was used.
                const { credential } = error;
                // ...
            });
    };
    const facebookHandler = async () => {
        setLoadingFacebook(true)
        const provider = new firebase.auth.FacebookAuthProvider();
        firebase
            .auth()
            .signInWithPopup(provider)
            .then(async (result) => {
                window.location.href = '/';
            })
            .catch((error) => {
                setLoadingFacebook(false);
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const { email } = error;
                // The firebase.auth.AuthCredential type that was used.
                const { credential } = error;
                // ...
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                        <Button
                            disableElevation
                            fullWidth
                            className={classes.redButton}
                            onClick={googleHandler}
                            size="large"
                            variant="outlined"
                            disabled={loading}
                        >
                            <LinearProgress color="primary" />
                            <img src={Google} alt="google" width="20px" style={{marginRight: '16px'}} className={classes.loginIcon} /> {loginWithGoogleLabel}
                        </Button>
                    {loading && <LinearProgress color="primary"  variant="query"/>}
                    <br/>
                    <br/>
                    <Button
                            disableElevation
                            fullWidth
                            className={classes.redButton}
                            onClick={facebookHandler}
                            size="large"
                            variant="outlined"
                            disabled={loadingFacebook}
                        >
                            <LinearProgress color="primary" />
                            <img src={Facebook} alt="facebook" width="20px" style={{marginRight: '16px'}} className={classes.loginIcon} /> {loginWithFacebookLabel}
                        </Button>
                    {loadingFacebook && <LinearProgress color="primary"  variant="query"/>}
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default FirebaseLogin;
