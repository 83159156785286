import React, { createContext, useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useAuthState } from 'react-firebase-hooks/auth';
import Loader from '../ui-component/Loader';

import { checkEntry, firebase } from '../services/firebaseService';
import StorageManager from '../services/StorageManager';
import { USER_LANGUAGE } from '../services/StorageKeys';
import * as actions from '../store/actions';
import { useNavigate} from 'react-router-dom';

export const AuthContext = createContext();
const auth = firebase.auth();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isInitializing, setInitializing] = useState(true);
  const [user, loading, error] = useAuthState(auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;

    const initializeUser = async () => {
      try {
        const userInfo = user ? await fetchUserDetails(user) : null;
        setCurrentUser(userInfo);
      } catch (err) {
        console.error('Error initializing user:', err);
      } finally {
        setInitializing(false);
      }
    };

    initializeUser();
  }, [user, loading]);

  const fetchUserDetails = async (userInfo) => {
    if (!userInfo) return null;

    const user = { ...userInfo };
    const {
      isPaid,
      languageLocale,
      usedCount,
      languageLabel,
      commands,
      created,
      preferredLang,
      onboarded,
      langaugeName,
      translate,
      targetLanguage,
    } = await checkEntry('users', user.email);

    const updatedUser = {
      ...user,
      paid: isPaid,
      locale: languageLocale,
      languageLocale,
      languageName: langaugeName,
      languageLabel,
      usedCount: parseInt(usedCount, 10) || 0,
      commands,
      createdAt: created,
      preferredLang,
      onboarded,
      translate,
      targetLanguage,
    };

    dispatchUserActions(preferredLang, translate, targetLanguage, languageLocale);
    return updatedUser;
  };

  const dispatchUserActions = (preferredLang, translate, targetLanguage, locale) => {
    dispatch({ type: actions.PREFERRED_LANGUAGE_CHANGE_SUCCESS, data: preferredLang });
    dispatch({ type: actions.TARGET_LANGUAGE_STATUS_CHANGE_SUCCESS, translate });
    dispatch({ type: actions.TARGET_LANGUAGE_CHANGE_SUCCESS, targetLanguage });
    StorageManager.put(USER_LANGUAGE, locale);
  };

  if (loading || isInitializing) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
